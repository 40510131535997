import { Component, Event, Prop, State, h } from '@stencil/core';
import months from '../../utils/months';
import { PTZCalendarTypes } from './types/ptz-calendar.types';

@Component({
  tag: 'ptz-calendar',
  styleUrl: 'ptz-calendar.scss',
  shadow: false,
})
export class PTZCalendar {
  /** Propriedade que define o valor padrão do calendário.  */
  @Prop() defaultValue?: PTZCalendarTypes.Args['defaultValue'];

  /** Propriedade que define se o calendário terá sombra.  */
  @Prop() boxShadow?: PTZCalendarTypes.Args['boxShadow'] = false;

  /** Propriedade que define se o calendário irá permitir selecionar datas no passado. */
  @Prop() allowPast?: PTZCalendarTypes.Args['allowPast'] = false;

  /** Estado que controla a visibilidade do calendário anual.  */
  @State() showYearCalendar: boolean = false;

  /** Estado que armazena a lista de dias do calendário.  */
  @State() daysList: PTZCalendarTypes.DaysList = [];

  /** Estado que armazena o índice do dia atualmente selecionado.  */
  @State() currentSelectDayIndex: number;

  /** Estado que armazena o rótulo do mês e ano atual exibido no calendário.  */
  @State() currentMonthYearLabel: string;

  /** Estado que armazena a data selecionada no calendário.  */
  @State() selectedDate?: Date;

  /** Estado que armazena o ano atual.  */
  @State() private year: number = new Date().getFullYear();

  /** Estado que armazena o mês atual.  */
  @State() private month: number = new Date().getMonth();

  /** Evento que é emitido quando ocorre uma alteração no calendário.  */
  @Event({ eventName: 'calendarChange' }) onCalendarChange?: PTZCalendarTypes.Args['onCalendarChange'];

  private get today(): Date {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return today;
  }

  private getFirstDayOfMonth(year: number, month: number): number {
    return new Date(year, month, 1).getDay();
  }

  private getLastDateOfMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  private getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month, this.getLastDateOfMonth(year, month)).getDay();
  }

  private getPreviousMonthLastDate(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  private generateCalendarHTML(year: number, month: number): void {
    let daysNewList: PTZCalendarTypes.DaysList = [];

    const firstDay = this.getFirstDayOfMonth(year, month);

    const lastDate = this.getLastDateOfMonth(year, month);

    const lastDay = this.getLastDayOfMonth(year, month);

    const previousMonthLastDate = this.getPreviousMonthLastDate(year, month);

    for (let i = firstDay; i > 0; i--) {
      daysNewList.push({ status: 'inactive', value: previousMonthLastDate - i + 1 });
    }

    for (let i = 1; i <= lastDate; i++) {
      daysNewList.push({ status: undefined, value: i });
    }
    for (let i = lastDay; i < 6; i++) {
      daysNewList.push({ status: 'inactive', value: i - lastDay + 1 });
    }

    this.daysList = daysNewList;
  }

  private updateCurrentDateText(year: number, month: number): void {
    this.currentMonthYearLabel = `${months[month]} de ${year}`;
  }

  private manipulate(year: number, month: number): void {
    this.updateCurrentDateText(year, month);
    this.generateCalendarHTML(year, month);
  }

  private prevMonthClickHandler(): void {
    this.month = this.month === 0 ? 11 : this.month - 1;
    this.year = this.month === 11 ? this.year - 1 : this.year;
    this.manipulate(this.year, this.month);
  }

  private nextMonthClickHandler(): void {
    this.month = this.month === 11 ? 0 : this.month + 1;
    this.year = this.month === 0 ? this.year + 1 : this.year;
    this.manipulate(this.year, this.month);
  }

  private selectDay(day: number) {
    if (!this.allowPast) {
      const date = new Date(this.year, this.month, day);

      date.setHours(0, 0, 0, 0);

      const isDayBeforeToday = date < this.today;

      if (isDayBeforeToday) {
        return;
      }
    }

    const newDateSelecteDate = new Date(this.year, this.month, day);

    newDateSelecteDate.setHours(0, 0, 0, 0);

    this.selectedDate = newDateSelecteDate;

    this.onChangeValues(day);
  }

  onChangeValues(day: number) {
    this.onCalendarChange?.emit(new Date(this.year, this.month, day));
  }

  componentWillLoad() {
    this.parseDefaultValue();

    this.manipulate(this.year, this.month);
  }

  private parseDefaultValue() {
    if (this.defaultValue) {
      const parsedDate = new Date(this.defaultValue);

      if (!parsedDate.getDate()) {
        console.warn('Error parsing date:');
        return;
      }

      this.month = parsedDate.getMonth();

      this.year = parsedDate.getFullYear();

      this.selectDay(parsedDate.getDate());
    }
  }

  private selectedClass(value: number, status: string): string {
    if (status === 'inactive') {
      return status;
    }

    const date = new Date(this.year, this.month, value);

    date.setHours(0, 0, 0, 0);

    const selectedDateClass = date.getTime() === this.selectedDate?.getTime() ? 'selected ' : '';

    return `${selectedDateClass}`;
  }

  private isToday(value: number) {
    return value === this.today.getDate() && this.today.getMonth() === this.month && this.year === this.today.getFullYear() ? 'today' : undefined;
  }

  render() {
    return (
      <div class={`calendar-container ${this.boxShadow ? 'shadow' : ''}`}>
        {this.showYearCalendar ? (
          <ptz-calendar-year
            onClickHeader={() => (this.showYearCalendar = !this.showYearCalendar)}
            labelHeader={`${months[this.month]} de ${this.year}`}
            defaultValue={this.year}
            onCalendarChange={value => {
              this.year = value.detail;
              this.manipulate(value.detail, this.month);
            }}
          />
        ) : (
          <div>
            <header class="calendar-header">
              <button class="calendar-current-date" onClick={() => (this.showYearCalendar = !this.showYearCalendar)}>
                <span> {`${months[this.month]} de ${this.year}`} </span> <ptz-icon variant="solid" size="xl" name="angle-down-solid" />{' '}
              </button>
              <div class="calendar-navigation">
                <button onClick={() => this.prevMonthClickHandler()}>
                  <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-left-solid" />
                </button>
                <button onClick={() => this.nextMonthClickHandler()}>
                  <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-right-solid" />
                </button>
              </div>
            </header>

            <div class="calendar-body">
              <ul class="calendar-weekdays">
                <li>D</li>
                <li>S</li>
                <li>T</li>
                <li>Q</li>
                <li>Q</li>
                <li>S</li>
                <li>S</li>
              </ul>
              <ul class="calendar-dates">
                {this.daysList?.map(({ status, value }) => (
                  <li
                    onClick={status != 'inactive' ? () => this.selectDay(value) : undefined}
                    class={this.selectedClass(value, status)}
                    id={status != 'inactive' && this.isToday(value)}
                  >
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}
